<template>
  <EncuestasSoftinm></EncuestasSoftinm>
</template>

<script setup>
import { ref, onMounted, nextTick } from "vue";
onMounted(() => {
  console.log("as")
});
// @ is an alias to /src
import EncuestasSoftinm from '@/components/EncuestasSoftinm.vue'

</script>
