<template>
  
  <router-view/>
</template>

<style lang="scss">
// #app {
//   // font-family: Avenir, Helvetica, Arial, sans-serif;
//   // -webkit-font-smoothing: antialiased;
//   // -moz-osx-font-smoothing: grayscale;
//   // text-align: center;
//   // color: #2c3e50;
// }



html, body {
    height: 100%;
}

.wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -20px;   
}
#app {
  height: 100%;
}


</style>
