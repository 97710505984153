<template>
  <div
    v-if="objEncuesta"
    class="wrapper"
    :style="{ backgroundColor: objEncuesta.colorFondo }"
  >
    <div style="padding: 20px" v-if="!objEncuesta.respondida">
      <img
        v-if="objEncuesta.bannerEncabezado"
        :src="objEncuesta.bannerEncabezado"
        style="width: 100%"
      />

      <div class="contenedorPregunta sombra mb-20">
        <div class="tituloEncuesta">{{ objEncuesta.titulo }}</div>
        <div class="descripcionEncuesta">
          {{ objEncuesta.descripcionEncuesta }}
        </div>
      </div>

      <div
        :class="
          pregunta.error
            ? 'contenedorPreguntaError sombra mb-20'
            : 'contenedorPregunta sombra mb-20'
        "
        v-for="pregunta in objEncuesta.preguntas"
        :key="pregunta.idPregunta"
      >
        <div
          class="tituloPreguntas"
          :style="{ color: objEncuesta.colorTitulos }"
        >
          {{ pregunta.tituloPregunta }}
          <b v-if="pregunta.obligatoria" class="lblObligatoria">(*)</b>
        </div>
        <template v-if="pregunta.tipoPregunta == 1">
          <el-input
            :disabled="false"
            v-model="pregunta.respuesta"
            @change="cambiaPregunta(pregunta)"
          />
        </template>
        <template v-if="pregunta.tipoPregunta == 2">
          <el-input
            :disabled="false"
            type="textarea"
            rows="2"
            v-model="pregunta.respuesta"
            @change="cambiaPregunta(pregunta)"
          />
        </template>
        <template v-if="pregunta.tipoPregunta == 3">
          <div v-for="(opcion, i) in pregunta.valores" :key="i">
            <el-radio
              v-model="pregunta.respuesta"
              :label="opcion.value"
              @change="cambiaPregunta(pregunta)"
              >{{ opcion.value }}</el-radio
            >
          </div>
        </template>
        <template v-if="pregunta.tipoPregunta == 4">
          <preguntaCheckBox
            v-model:respuesta="pregunta.respuesta"
            :opciones="pregunta.valores"
            @change="cambiaPregunta(pregunta)"
          ></preguntaCheckBox>
        </template>
        <template v-if="pregunta.tipoPregunta == 5">
          <el-select
            v-model="pregunta.respuesta"
            @change="cambiaPregunta(pregunta)"
          >
            <el-option
              v-for="item in pregunta.valores"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </div>

      <br />
      <el-button
        class="fullw mb-20"
        type="primary"
        :icon="Promotion"
        @click="enviarEncuesta"
        :style="{
          backgroundColor: objEncuesta.colorBotonEnviar,
          borderColor: objEncuesta.colorBotonEnviar,
        }"
        >{{ objEncuesta.textoBotonEnviar }}</el-button
      >

      <img
        v-if="objEncuesta.bannerPie"
        :src="objEncuesta.bannerPie"
        style="width: 100%"
      />
    </div>
    <div v-else>
      <div
        class="mensajeAgradecimiento"
        v-html="objEncuesta.textoAgradecimiento"
      ></div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
import { ref, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { ElNotification } from "element-plus";
import { Promotion } from "@element-plus/icons-vue";
// eslint-disable-next-line
import Cookies from "js-cookie";
import preguntaCheckBox from "@/components/preguntaCheckBox.vue";
const store = useStore();
// const etoken = ref(
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjEiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiNDYzMTkiLCJyZWYiOiIxIiwiaWRVcmwiOiIzMDA2NyIsInRrVXJsIjoiLTRrdDd5ZzY5dGFmIiwiaWRlbXByZXNhIjoiODExMDMwNzAyMiIsInB0IjoidWN2dWd3ZXBHIiwiZXhwIjoxNzI5ODAyNTQwLCJpc3MiOiJodHRwczovL2JldGF6Yy5zb2Z0aW5tLmNvbS8iLCJhdWQiOiJodHRwczovL2JldGF6Yy5zb2Z0aW5tLmNvbS8ifQ.9tjJuu6dptjA1hc46O6Mwb_rbSG4GvS6sWa1ioI0qw0"
// );

const etoken = ref("");

const objEncuesta = ref(null);

onMounted(() => {
  nextTick(() => {
    etoken.value = Cookies.get("encuestaToken");
    if (etoken.value) {
      consultarEncuesta();
    } else {
      console.log("No Token");
      store.dispatch(
        "app/setSetMensajeError",
        "No estas autorizado para usar esta aplicación por favor comunícate con la persona que te envió este enlace"
      );
      router.push({ name: "errorGrave" });
      //this.$store.dispatch("app/setobjEmp", objRespuesta.data);
    }
  });
});

const consultarEncuesta = () => {
  axios
    .get("encuestas/consultar", {
      headers: { Authorization: "Bearer " + etoken.value },
    })
    .then((response) => {
      // this.egresos = response.data;

      let objRespuesta = response.data;
      if (objRespuesta.exito && objRespuesta.data) {
        objEncuesta.value = objRespuesta.data;
      }
    })
    .catch((error) => {
      if (error.response) {
        let objRespuesta = error.response.data;
        store.dispatch("app/setSetMensajeError", objRespuesta.mensaje);
        router.push({ name: "errorGrave" });
      } else if (error.request) {
        store.dispatch("app/setSetMensajeError", "No hay conexion con el API");
        router.push({ name: "errorGrave" });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    })
    .finally(() => {});
};

const cambiaPregunta = (pregunta) => {
  if (pregunta.respuesta == "" && pregunta.obligatoria) {
    pregunta.error = true;
  } else {
    pregunta.error = false;
  }
};

const enviarEncuesta = () => {
  objEncuesta.value.preguntas.forEach((element) => {
    if (element.respuesta == "" && element.obligatoria) {
      element.error = true;
    } else {
      element.error = false;
    }
  });

  let conError = false;
  conError = objEncuesta.value.preguntas.some((e) => e.error);
  //console.log(conError);

  if (!conError) {
    axios
      .post("encuestas/registrar_respuestas", objEncuesta.value, {
        headers: { Authorization: "Bearer " + etoken.value },
      })
      .then((response) => {
        // this.egresos = response.data;
        let objRespuesta = response.data;
        if (objRespuesta.exito && objRespuesta.data) {
          objEncuesta.value.respondida = true;
          ElNotification({
            title: "Muy bien 👍",
            message: "Las respuestas se registraron correctamente",
            type: "success",
            duration: 4000,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          let objRespuesta = error.response.data;
          store.dispatch("app/setSetMensajeError", objRespuesta.mensaje);
          router.push({ name: "errorGrave" });
        } else if (error.request) {
          store.dispatch(
            "app/setSetMensajeError",
            "No hay conexion con el API"
          );
          router.push({ name: "errorGrave" });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      })
      .finally(() => {});
  } else {
    ElNotification({
      title: "Error 😖",
      message: "Debe completar las preguntas obligatorias...",
      type: "error",
      duration: 4000,
    });
  }
};
</script>
<style lang="scss" scoped>
.contenedorPregunta {
  position: relative;

  background-color: #fff;
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
  border-radius: 7px;
  margin: 20px;
  padding: 20px;
}
.contenedorPreguntaError {
  position: relative;

  background-color: #ffa7a7;
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
  border-radius: 7px;
  margin: 20px;
  padding: 20px;
}
.sombra {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
}

.tituloEncuesta {
  font-size: 20px;
  font-weight: bold;
}
.tituloPreguntas {
  font-size: 18px;
  font-weight: lighter;
  margin-bottom: 5px;
}
.descripcionEncuesta {
  font-size: 14px;
  font-weight: lighter;
  margin-top: 5px;
  text-align: justify;
  text-align-last: left;
}

.mensajeAgradecimiento {
  text-align: center;
  padding-top: 80px;
  font-size: 30px;
  font-weight: lighter;
  margin-bottom: 5px;
}

.lblObligatoria {
  color: red;
}
</style>
