<template>
  <el-checkbox-group v-model="lsRespuestas" @change="cambiaRespuesta">
    <div v-for="(opcion, i) in props.opciones" :key="i">
      <br />
      <el-checkbox :label="opcion.value" size="large" />
    </div>
  </el-checkbox-group>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
const props = defineProps({
  respuesta: String,
  opciones: Array,
});
// eslint-disable-next-line
const emit = defineEmits(["update:respuesta","change"]);
const lsRespuestas = ref([]);
const cambiaRespuesta = () => {
  emit("update:respuesta", lsRespuestas.value.join(","));
  emit("change")
};
</script>

<style lang="scss" scoped></style>
