const state = {

  mensajeErrorGrave: ''
}

const mutations = {

  SET_MENSAJE_ERROR: (state, mensajeError) => {
    state.mensajeErrorGrave = mensajeError
  },
  
}

const actions = {

  setSetMensajeError({ commit }, mensajeError) {
    commit('SET_MENSAJE_ERROR', mensajeError)
  },
 
 

  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}


