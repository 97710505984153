import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import './styles/element-variables.scss'
// import 'element-plus/dist/index.css'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'

import '@/styles/index.scss' // global css

//axios.defaults.baseURL="https://localhost:44307/api/"
 axios.defaults.baseURL="https://zonaclientes.softinm.com/api/"
createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
